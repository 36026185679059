import { Capacitor } from '@capacitor/core'

export default defineNuxtRouteMiddleware(async () => {
	const store = useUserStore()
	const user = await useApi('/auth/me')
	const isWeb = Capacitor.getPlatform() === 'web'
	if (!user) {
		store.logout()
		if (isWeb) {
			return navigateTo('/lp')
		}

		if (!isWeb) {
			return navigateTo('/welcome')
		}

		return navigateTo('/login')
	}
})
